import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase';
import axios from 'axios';

Vue.use(VueRouter)
const isLoggedIn = () => firebase.auth().currentUser;


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      title:
        ' KantooorFlex | Huren van een kantoorRuimte in Nederland',
    },
  },
  {
    path: '/Overons',
    name: 'Overons',
    component: () => import(/* webpackChunkName: "Overons" */ '../views/Overons.vue'),
    meta: {
      title:
        ' Flexplek huren verhuren | Flexibele kantoorruimte | Kantoorflex',
    },
  },
  {
    path: '/Aanbod',
    name: 'Aanbod',
    component: () => import(/* webpackChunkName: "Werkplekken" */ '../views/Aanbod.vue'),
    meta: {
      title:
        ' Jouwbedrijfsmakelaar | Actueel aanbod',
    },
  },
  {
    path: '/Hoehetwerkt',
    name: 'Hoehetwerkt',
    component: () => import(/* webpackChunkName: "Hoehetwerkt" */ '../views/Hoehetwerkt.vue'),
    meta: {
      title:
        ' Jouwbedrijfsmakelaar | hoe gaan wij te werk ',
    },
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Overons" */ '../views/Contact.vue'),
    meta: {
      title:
        ' Jouwbedrijfsmakelaar | Contact opnemen ',
    },
  },
  {
    path: '/Single/:id',
    name: 'Single',
    component: () =>
      import(/* webpackChunkName: "singleHouse" */ '@/views/Single'),
    meta: { title: ' Jouwbedrijfsmakelaar | pand informatie' },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '@/views/404.vue'),
    meta: { title: ' Kantoorflex | Oeps pagina niet gevonden' },
  },
]


async function setFirebaseToken() {
  const token = (await isLoggedIn())
    ? await firebase.auth().currentUser.getIdToken(true)
    : null;
  if (token) axios.defaults.headers.common['fireToken'] = token;
}

async function onAuthRequired(to, from, next) {
  document.title = to.meta.title || 'Kantoorflex';
  await setFirebaseToken();

  if (to.meta.requiresAuth) {
    if (!isLoggedIn()) {
      next({
        path: '/Login',
        query: { redirect: to.fullPath },
      });
      return;
    }
  }
  next();
}

const router = new VueRouter({
  mode: 'history',
  //Dit voor weer naar begin
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(onAuthRequired);
export default router;